import "./menu.scss";
import { useEffect, useLayoutEffect, useState } from "react";

const MOBILE_MENU_WIDTH = 1440;

export default function Menu() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState("none");
  const [subMenuOpen, setSubMenuOpen] = useState("none");

  useLayoutEffect(() => {
    function checkWidth() {
      if (window.innerWidth > MOBILE_MENU_WIDTH && menuOpen === "none" && hamburgerOpen) {
        document.getElementsByTagName('html')[0].classList.remove("nav-open");
        setHamburgerOpen(false);
      }
    }
    window.addEventListener('resize', checkWidth);
    return () => window.removeEventListener('resize', checkWidth);
  }, [menuOpen, hamburgerOpen]);

  useEffect(() => {
    if (hamburgerOpen || menuOpen !== "none") {
      document.getElementsByTagName('html')[0].classList.add("nav-open");
    } else {
      document.getElementsByTagName('html')[0].classList.remove("nav-open");
    }
  }, [hamburgerOpen, menuOpen])


  const menuClick = (name) => {
    if (menuOpen !== name) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      setMenuOpen(name);
      setHamburgerOpen(true);
      setSubMenuOpen("none");
    } else {
      setMenuOpen("none");
      setSubMenuOpen("none");
      if (hamburgerOpen && window.innerWidth > MOBILE_MENU_WIDTH) {
        setHamburgerOpen(false);
      }
    }
  }

  const subMenuClick = (name) => {
    if (subMenuOpen === name) {
      setSubMenuOpen("none");
    } else {
      setSubMenuOpen(name);
    }
  }

  const hamburgerClick = () => {
    if (hamburgerOpen) {
      setHamburgerOpen(false);
      setMenuOpen("none");
      setSubMenuOpen("none");
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      setHamburgerOpen(true);

    }
  }

  return (
    <div className="menu">
      <div className={"hamburger" + (hamburgerOpen ? " open" : "")} onClick={() => hamburgerClick()}>
        <span className="line1"></span>
        <span className="line2"></span>
        <span className="line3"></span>
      </div>
      <nav className={"main-menu" + (hamburgerOpen ? " active" : "")}>
        <ul className="nav-main">
          <li className="menu-item" ><span onClick={() => menuClick("branze")}>Branże</span>
            <div className={"menu-content" + (menuOpen === "branze" ? " active" : "")}>
              <div className="menu-wrapper">
                <div className="menu-column">
                  <ul>
                    <li className={subMenuOpen === "meble-na-wymiar" ? "active" : ""}><span onClick={() => subMenuClick("meble-na-wymiar")}>Meble na wymiar</span>
                      <ul className={"mobile-submenu" + (subMenuOpen === "meble-na-wymiar" ? " active" : "")}>
                        <li>MEBEL 1</li>
                        <li>MEBEL 2</li>
                        <li>MEBEL 3</li>
                        <li>MEBEL 4</li>
                        <li>MEBEL 5</li>
                        <li>MEBEL 6</li>
                      </ul>
                    </li>
                    <li className={subMenuOpen === "meble-tapicerowane" ? "active" : ""}><span onClick={() => subMenuClick("meble-tapicerowane")}>Meble tapicerowane</span>
                      <ul className={"mobile-submenu" + (subMenuOpen === "meble-tapicerowane" ? " active" : "")}>
                        <li>TAPICEROWANE 1</li>
                        <li>TAPICEROWANE 2</li>
                        <li>TAPICEROWANE 3</li>
                        <li>TAPICEROWANE 4</li>
                        <li>TAPICEROWANE 5</li>
                        <li>TAPICEROWANE 6</li>
                      </ul>
                    </li>
                    <li className={subMenuOpen === "drewno-lite" ? "active" : ""}><span onClick={() => subMenuClick("drewno-lite")}>Drewno lite</span>
                      <ul className={"mobile-submenu" + (subMenuOpen === "drewno-lite" ? " active" : "")}>
                        <li>DREWNO 1</li>
                        <li>DREWNO 2</li>
                        <li>DREWNO 3</li>
                        <li>DREWNO 4</li>
                        <li>DREWNO 5</li>
                        <li>DREWNO 6</li>
                      </ul>
                    </li>
                    <li className={subMenuOpen === "produkcja-domow" ? "active" : ""}><span onClick={() => subMenuClick("produkcja-domow")}>Produkcja domów</span>
                      <ul className={"mobile-submenu" + (subMenuOpen === "produkcja-domow" ? " active" : "")}>
                        <li>DOMY 1</li>
                        <li>DOMY 2</li>
                        <li>DOMY 3</li>
                        <li>DOMY 4</li>
                        <li>DOMY 5</li>
                        <li>DOMY 6</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className={"sub-menu" + (subMenuOpen === "meble-na-wymiar" ? " active" : "")}>
                  <div className="submenu-column menu-banner">
                    <a href="/" >MEBLE NA WYMIAR</a>
                    <a className="button-color" href="/infotec-evo-go">INFOTEC EVO GO</a>
                  </div>
                  <div className="submenu-column">
                    <ul>
                      <li>MEBEL 1</li>
                      <li>MEBEL 2</li>
                      <li>MEBEL 3</li>
                      <li>MEBEL 4</li>
                      <li>MEBEL 5</li>
                      <li>MEBEL 6</li>
                    </ul>
                  </div>
                </div>
                <div className={"sub-menu" + (subMenuOpen === "meble-tapicerowane" ? " active" : "")}>
                  <div className="submenu-column menu-banner">
                    <a href="/" >MEBLE TAPIEROWANE</a>
                    <a className="button-color" href="/">INFOTEC EVO 2</a>
                  </div>
                  <div className="submenu-column">
                    <ul>
                      <li>TAPICEROWANIE 1</li>
                      <li>TAPICEROWANIE 2</li>
                      <li>TAPICEROWANIE 3</li>
                      <li>TAPICEROWANIE 4</li>
                      <li>TAPICEROWANIE 5</li>
                      <li>TAPICEROWANIE 6</li>
                    </ul>
                  </div>
                </div>
                <div className={"sub-menu" + (subMenuOpen === "drewno-lite" ? " active" : "")}>
                  <div className="submenu-column menu-banner">
                    <a href="/" >DREWNO LITE</a>
                    <a className="button-color"  href="/">INFOTEC EVO BASIC</a>
                  </div>
                  <div className="submenu-column">
                    <ul>
                      <li>DREWNO 1</li>
                      <li>DREWNO 2</li>
                      <li>DREWNO 3</li>
                      <li>DREWNO 4</li>
                      <li>DREWNO 5</li>
                      <li>DREWNO 6</li>
                    </ul>
                  </div>
                </div>
                <div className={"sub-menu" + (subMenuOpen === "produkcja-domow" ? " active" : "")}>
                  <div className="submenu-column menu-banner">
                    <a href="/" >PRODUKCJA DOMÓW</a>
                    <a className="button-color"  href="/">INFOTEC X</a>
                  </div>
                  <div className="submenu-column">
                    <ul>
                      <li>DOM 1</li>
                      <li>DOM 2</li>
                      <li>DOM 3</li>
                      <li>DOM 4</li>
                      <li>DOM 5</li>
                      <li>DOM 6</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="menu-item" ><span onClick={() => menuClick("engineering")}>Engineering</span>
            <div className={"menu-content" + (menuOpen === "engineering" ? " active" : "")}>
              <div className="menu-wrapper">
                <div className="menu-column"><ul><li>SUBMENU Engineering</li></ul></div>
              </div>
            </div>
          </li>
          <li className="menu-item"><span onClick={() => menuClick("power-tools")}>Power tools</span>
            <div className={"menu-content" + (menuOpen === "power-tools" ? " active" : "")}>
              <div className="menu-wrapper">
                <div className="menu-column"><ul><li>SUBMENU Power tools</li></ul></div>
              </div>
            </div>
          </li>
          <li className="menu-item"><span onClick={() => menuClick("infotec-world")}>INFOTEC World</span>
            <div className={"menu-content" + (menuOpen === "infotec-world" ? " active" : "")}>
              <div className="menu-wrapper">
                <div className="menu-column"><ul><li>SUBMENU INFOTEC World</li></ul></div>
              </div></div>
          </li>
          <li className="menu-item"><span onClick={() => menuClick("szkolenia")}>Szkolenia</span>
            <div className={"menu-content" + (menuOpen === "szkolenia" ? " active" : "")}>
              <div className="menu-wrapper">
                <div className="menu-column"><ul><li>SUBMENU Szkolenia</li></ul></div>
              </div></div>
          </li>
        </ul>
      </nav>
    </div >
  );
}
