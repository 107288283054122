import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./components/home/Home"
import Footer from "./components/footer/Footer";
import EvoGO from "./components/products/EvoGO";
import "./app.scss"

function App() {
  return (

    <div className="app">
      <Header />
      <div className="sections">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/infotec-evo-go" element={<EvoGO />} />
          </Routes>
        </Router>
        <Footer />
      </div>
    </div >

  );
}

export default App;
