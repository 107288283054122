import "./header.scss";
import Menu from "../menu/Menu";
import PlaceIcon from '@mui/icons-material/Place';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';

export default function Topbar() {
  return (
    <header className="header">
      <div className="wrapper">
        <div className="left">
          <Menu />
        </div>
        <div className="right">
          <div className="itemContainer">
            <BuildOutlinedIcon className="icon" />
            <span>SERWIS</span>
          </div>
          <div className="itemContainer">
            <PlaceIcon className="icon" />
            <span>DILERZY</span>
          </div>
          <a href="/" className="logo">
            <img src="assets/infotec-logo.svg" alt="logo" />
          </a>
        </div>
      </div>
    </header>
  );
}
