export const posts = [
    {
        id: 1,
        link: "#",
        title: "Dotacje na maszyny 2023",
        date: "2023/04/07",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        img: "assets/DSC04948_1.webp",
        alt: "dotacje-na-maszyny-2023"
    },
    {
        id: 2,
        link: "#",
        title: "Jakie narzędzia?",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        date: "2023/02/07",
        img: "assets/DSC04219_1.webp",
        alt: "jakie-narzedzia"
    },
    {
        id: 3,
        link: "#",
        title: "TPA – bez tajemnic",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        date: "2023/02/02",
        img: "assets/DSC04128_1.webp",
        alt: "tpa"
    }
]


export const machines = [
    {
        id: 4,
        link: "#",
        title: "EVO BASIC",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        img: "assets/DSC07653.webp",
        alt: "infotec EVO BASIC"
    },
    {
        id: 5,
        link: "#",
        title: "EVO",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        img: "assets/DSC07653.webp",
        alt: "infotec EVO"
    },
    {
        id: 6,
        link: "#",
        title: "EVO 3",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        date: "2023/02/02",
        img: "assets/DSC07653.webp",
        alt: "infotec EVO 3"
    },
    {
        id: 7,
        link: "#",
        title: "EVO LITE",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        img: "assets/wiz-final-1-1.webp",
        alt: "infotec EVO LITE"
    },
    {
        id: 8,
        link: "#",
        title: "EVO PRO PLUS",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        img: "assets/sm_nesting.webp",
        alt: "infotec EVO PRO PLUS"
    },
    {
        id: 9,
        link: "#",
        title: "EVO-GO",
        short_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis purus vel elit consectetur tempus.",
        date: "2023/02/02",
        img: "assets/DSC04559_1.webp",
        alt: "infotec EVO-GO"
    }
]


export const params_frame = [
    {
        id: 10,
        slug: "hiteco-8_5-kW-hsk63f",
        link: "#",
        title: "Elektrowrzeciono HITECO 8,5 kW | HSK 63F",
        short_description: "Przemysłowe elektrowrzeciono renomowanego producenta, firmy HITECO, o mocy 8,5 kW (S1) z mocowaniem na stożki w standardzie HSK 63F. Elektrowrzeciono frezarskie chłodzone powietrzem, zbudowane na łożyskach ceramicznych. Zakres pracy jednostki od 1 000 do 24 000 obr./min. Wrzeciono przystosowane do automatycznej wymiany narzędzi, co znacznie wpływa na skrócenie czasu obróbki detali przez eliminację przestojów związanych z ręcznym przezbrojeniem maszyny.<ul><li>Moc (S1) 8,5 kW</li><li>Standard HSK 63F</li><li>Praca w zakresie od 1 000 do 24 000 obr./min.</li><li>Nadmuch sprężonego powietrza podczas wymiany narzędzia</li></ul>",
        img: "assets/Elektrowrzeciono HITECO 8_5 kW-HSK 63F.webp",
        alt: "Elektrowrzeciono HITECO 8,5 kW HSK 63F"
    }
]

export const params_mechanics = [
    {
        id: 11,
        slug: "rama_i_brama_maszyny_evo_go",
        link: "#",
        title: "RAMA I BRAMA MASZYNY",
        short_description: "Wszystkie elementy konstrukcyjne maszyny wykonane są z wysokiej jakości stali spawanej elektrycznie. Wszystkie profile są żebrowane i wzmacniane wewnętrznie tak aby zagwarantować maksymalną sztywność korpusu. Cała konstrukcja ponadto jest obrabiana z jednego zamocowania na wielkogabarytowym centrum obróbczym, zapewnia to maksymalną precyzje wykonania i prawidłowe przygotowanie płaszczyzn pod montaż elementów ruchu liniowego. Przed procesem obróbki mechanicznej zarówno brama i rama maszyny poddawane są odprężaniu w celu redukcji naprężeń powstałych podczas procesu spawania.",
        img: "assets/rama_infotec_evo_go.webp",
        alt: "rama infotec evo go"
    }
]

export const params_software = [
    {
        id: 12,
        slug: "Kontroler_TPA_WSCM",
        link: "#",
        title: "Kontroler TPA - WSCM",
        short_description: "Kontroler WSCM TPA to zaawansowany sterowany za pomocą protokołu Ethercat sterownik przemysłowy, który dzięki wykorzystaniu najnowszych technologii z zakresu kontroli maszyn CNC gwarantuje dużą szybkość pracy maszyny, stabilną i bezproblemowa prace podczas użytkowania urządzenia.",
        img: "assets/Kontroler TPA - WSCM.webp",
        alt: "Kontroler TPA - WSCM"
    }
]

export const params_tabke = [
    {
        id: 13,
        slug: "2_pompy_lopatkowe_250m3",
        link: "#",
        title: "2 pompy łopatkowe 250m3",
        short_description: "Podłączenie pompy próżniowej zwiększa siłę mocowania elementów na stole maszyny oraz daje pewność wykonania poprawnie obróbki nawet przy wysokim zagęszczeniu formatek.",
        img: "assets/pompa lopatkowa 250m3.webp",
        alt: "pompa łopatkowa 250m3"
    }
]

export const params_addons = [
    {
        id: 14,
        slug: "przeniesienie_napedu",
        link: "#",
        title: "Przeniesienie napędu",
        short_description: "Brama maszyny wraz z osprzętem porusza się wzdłuż osi X i Y za pośrednictwem mechanizmu zębatego o skośnym uzębieniu wykonanym z utwardzanej i hartowanej stali, do przeniesienia napędu między serwo silnikiem a kołem zębatym wykorzystywana jest precyzyjna bezluzowa jednostopniowa przekładnia planetarna. Takie rozwiązanie zapewnia duże wartości przyśpieszeń i prędkości skrawania. Zastosowane listwy jak i koła zębate wykonane są w klasie dokładności DIN 6 daje to gwarancje długowieczności tych podzespołów oraz wysoką precyzję pozycjonowania. Napęd w osi Z (przesuw pionowy) gdzie szczególną rolę odgrywa precyzja pozycjonowania realizowany jest za pomocą śruby kulowej z nakrętką z napięciem wstępnym marki BOSCH.",
        img: "assets/naped-infotec-evo-go.webp",
        alt: "napęd infotec evo go"
    }
]


export const upgrades = [
    {
        id: 15,
        link: "#",
        title: "Podniesienie osi Z do 350 mm",
        img: "assets/upgrade1.webp",
        alt: "podniesienie osi Z 1"
    },
    {
        id: 16,
        link: "#",
        title: "Podniesienie osi Z do 300 mm",
        img: "assets/upgrade1.webp",
        alt: "podniesienie osi Z 2"
    },
    {
        id: 17,
        link: "#",
        title: "Podniesienie osi Z do 250 mm",
        img: "assets/upgrade1.webp",
        alt: "podniesienie osi Z 3"
    },
    {
        id: 18,
        link: "#",
        title: "Podniesienie osi Z do 200 mm",
        img: "assets/upgrade1.webp",
        alt: "podniesienie osi Z 4"
    }
]