import "./products.scss"
import YouTubeIcon from '@mui/icons-material/YouTube';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useState, useEffect } from "react";
import { upgrades } from "../../data";
//import parse from 'html-react-parser';

export default function EvoGO() {
    const [scroll, setScroll] = useState("frame");

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
    }, []);
    const scrollHandler = () => {
        let menu = document.querySelector('.header')
        let sections = [document.getElementById('frame'), document.getElementById('mechanics'), document.getElementById('software'), document.getElementById('table'), document.getElementById('add-ons')]
        let pos_menu = window.pageYOffset + menu.offsetHeight
        let pos = [];
        let dist = [];
        sections.forEach((section) => {
            pos.push({
                id: section.id,
                distance: (section.offsetTop + section.offsetHeight) - pos_menu - 200
            });
            dist.push((section.offsetTop + section.offsetHeight) - pos_menu - 200);
        })

        let min = Math.min(...dist.filter(num => num > 0))
        //console.log(pos);
        if (min !== Infinity) {
            pos.forEach((elem) => {
                if (min === elem.distance) setScroll(elem.id);
            })
        } else {
            setScroll("none");
        }
    }


    return (
        <div className="product">
            <div id="evo-go" className="slider">
                <div className="wrapper">
                    <h2>REWOLUCYJNE CENTRUM NESTINGOWE</h2>
                    <h1>INFOTEC EVO-GO</h1>
                    <a className="button-color" href="/">
                        OFERTA
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="product-description">
                    <div className="right">
                        <span className="spec"><DownloadIcon className="icon" /> POBIERZE SPECYFIKACJĘ</span>
                        <img src="assets/infotec-evo-go.webp" alt="maszyna infotec evo go" />
                    </div>
                    <div className="left">
                        <h2 className="title">INFOTEC EVO-GO</h2>
                        <div className="price">
                            CENA BAZOWA 550 000,00 PLN
                        </div>
                        <p>Red Bull KTM Factory Racing’s Manuel Lettenbichler has won the 2022 FIM Hard Enduro World Championship. Claiming a dominant victory at the eighth and final round of the series - Hixpania Hard Enduro - the young German</p>
                        <div className="links">
                            <span className="link"><YouTubeIcon className="icon" /> PREZENTACJA</span>
                            <span className="link"><YouTubeIcon className="icon" /> PRACA</span>
                        </div>
                    </div>

                </div>
            </div>
            <div className={"left-nav" + (scroll === "none" ? " disabled" : "")}>
                <span className={scroll === "frame" ? "active" : ""}>01. <p className="nav-desc">KONSTRUKCJA RAMY</p></span>
                <span className={scroll === "mechanics" ? "active" : ""}>02. <p className="nav-desc">KONSTRUKCJA</p></span>
                <span className={scroll === "software" ? "active" : ""}>03. <p className="nav-desc">OPROGRAMOWANIE</p></span>
                <span className={scroll === "table" ? "active" : ""}>04. <p className="nav-desc">STÓŁ ROBOCZY WRAZ Z OSPRZĘTEM</p></span>
                <span className={scroll === "add-ons" ? "active" : ""}>05. <p className="nav-desc">OSPRZĘT TOWARZYSZĄCY</p></span>
            </div>
            <div className="row">
                <div className="parameters">
                    <div id="frame">
                        <h3 className="title">01. KONSTRUKCJA RAMY</h3>
                        <div className="params-slider">
                            <KeyboardArrowLeftIcon className="arr-icon left" />
                            <div className="slide">
                                <div className="image">
                                    <img src="assets/Elektrowrzeciono HITECO 8_5 kW-HSK 63F.webp" alt="elektrowrzeciono" />
                                </div>
                                <div className="description">
                                    <h4>Elektrowrzeciono HITECO 8,5 kW | HSK 63F</h4>
                                    <p>Przemysłowe elektrowrzeciono renomowanego producenta, firmy HITECO, o mocy 8,5 kW (S1) z mocowaniem na stożki w standardzie HSK 63F. Elektrowrzeciono frezarskie chłodzone powietrzem, zbudowane na łożyskach ceramicznych. Zakres pracy jednostki od 1 000 do 24 000 obr./min. Wrzeciono przystosowane do automatycznej wymiany narzędzi, co znacznie wpływa na skrócenie czasu obróbki detali przez eliminację przestojów związanych z ręcznym przezbrojeniem maszyny.</p>
                                    <ul>
                                        <li>Moc (S1) 8,5 kW</li>
                                        <li>Standard HSK 63F</li>
                                        <li>Praca w zakresie od 1 000 do 24 000 obr./min.</li>
                                        <li>Nadmuch sprężonego powietrza podczas wymiany narzędzia</li>
                                    </ul>
                                </div>
                            </div>
                            <KeyboardArrowRightIcon className="arr-icon right" />
                        </div>
                    </div>
                </div>
                <div className="parameters">
                    <div id="mechanics">
                        <h3 className="title">02. KONSTRUKCJA</h3>
                        <div className="params-slider">
                            <KeyboardArrowLeftIcon className="arr-icon left" />
                            <div className="slide">
                                <div className="image">
                                    <img src="assets/rama_infotec_evo_go.webp" alt="rama infotec evo go" />
                                </div>
                                <div className="description">
                                    <h4>RAMA I BRAMA MASZYNY</h4>
                                    <p>
                                        Wszystkie elementy konstrukcyjne maszyny wykonane są z wysokiej jakości stali spawanej elektrycznie. Wszystkie profile są żebrowane i wzmacniane wewnętrznie tak aby zagwarantować maksymalną sztywność korpusu. Cała konstrukcja ponadto jest obrabiana z jednego zamocowania na wielkogabarytowym centrum obróbczym, zapewnia to maksymalną precyzje wykonania i prawidłowe przygotowanie płaszczyzn pod montaż elementów ruchu liniowego. Przed procesem obróbki mechanicznej zarówno brama i rama maszyny poddawane są odprężaniu w celu redukcji naprężeń powstałych podczas procesu spawania.
                                    </p>
                                </div>
                            </div>
                            <KeyboardArrowRightIcon className="arr-icon right" />
                        </div>
                    </div>
                </div>
                <div className="parameters">
                    <div id="software">
                        <h3 className="title">03. OPROGRAMOWANIE</h3>
                        <div className="params-slider">
                            <KeyboardArrowLeftIcon className="arr-icon left" />
                            <div className="slide">
                                <div className="image">
                                    <img src="assets/Kontroler TPA - WSCM.webp" alt="Kontroler TPA - WSCM" />
                                </div>
                                <div className="description">
                                    <h4>Kontroler TPA - WSCM</h4>
                                    <p>
                                        Kontroler WSCM TPA to zaawansowany sterowany za pomocą protokołu Ethercat sterownik przemysłowy, który dzięki wykorzystaniu najnowszych technologii z zakresu kontroli maszyn CNC gwarantuje dużą szybkość pracy maszyny, stabilną i bezproblemowa prace podczas użytkowania urządzenia.
                                    </p>
                                </div>
                            </div>
                            <KeyboardArrowRightIcon className="arr-icon right" />
                        </div>
                    </div>
                </div>
                <div className="parameters">
                    <div id="table">
                        <h3 className="title">04. STÓŁ ROBOCZY WRAZ Z OSPRZĘTEM</h3>
                        <div className="params-slider">
                            <KeyboardArrowLeftIcon className="arr-icon left" />
                            <div className="slide">
                                <div className="image">
                                    <img src="assets/pompa lopatkowa 250m3.webp" alt="pompa łopatkowa 250m3" />
                                </div>
                                <div className="description">
                                    <h4>2 pompy łopatkowe 250m3</h4>
                                    <p>
                                        Podłączenie pompy próżniowej zwiększa siłę mocowania elementów na stole maszyny oraz daje pewność wykonania poprawnie obróbki nawet przy wysokim zagęszczeniu formatek.
                                    </p>
                                </div>
                            </div>
                            <KeyboardArrowRightIcon className="arr-icon right" />
                        </div>
                    </div>
                </div>
                <div className="parameters">
                    <div id="add-ons">
                        <h3 className="title">05. OSPRZĘT TOWARZYSZĄCY</h3>
                        <div className="params-slider">
                            <KeyboardArrowLeftIcon className="arr-icon left" />
                            <div className="slide">
                                <div className="image">
                                    <img src="assets/naped-infotec-evo-go.webp" alt="napęd infotec evo go" />
                                </div>
                                <div className="description">
                                    <h4>Przeniesienie napędu</h4>
                                    <p>
                                        Brama maszyny wraz z osprzętem porusza się wzdłuż osi X i Y za pośrednictwem mechanizmu zębatego o skośnym uzębieniu wykonanym z utwardzanej i hartowanej stali, do przeniesienia napędu między serwo silnikiem a kołem zębatym wykorzystywana jest precyzyjna bezluzowa jednostopniowa przekładnia planetarna. Takie rozwiązanie zapewnia duże wartości przyśpieszeń i prędkości skrawania. Zastosowane listwy jak i koła zębate wykonane są w klasie dokładności DIN 6 daje to gwarancje długowieczności tych podzespołów oraz wysoką precyzję pozycjonowania. Napęd w osi Z (przesuw pionowy) gdzie szczególną rolę odgrywa precyzja pozycjonowania realizowany jest za pomocą śruby kulowej z nakrętką z napięciem wstępnym marki BOSCH.
                                    </p>
                                </div>
                            </div>
                            <KeyboardArrowRightIcon className="arr-icon right" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="tech-sepc">
                    <h3>Charakterystyka techniczna</h3>
                    <div className="inner">
                        <div className="tech-data">
                            <div className="dimensions">
                                <h4>Wymiary i masa</h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="spec">Masa maszyny</td>
                                            <td>4200 kg</td>
                                        </tr>
                                        <tr>
                                            <td className="spec">Długość</td>
                                            <td>9100 mm</td>
                                        </tr>
                                        <tr>
                                            <td className="spec">Szerokość</td>
                                            <td>4100 mm</td>
                                        </tr>
                                        <tr>
                                            <td className="spec">Wysokość</td>
                                            <td>2700 mm</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="equipment">
                                <h4>Osprzęt</h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="spec">Wrzeciono</td>
                                            <td>Elektrowrzeciono HITECO 8,5 kW | HSK 63F</td>
                                        </tr>
                                        <tr>
                                            <td className="spec">Agregat wiertarski</td>
                                            <td>9100 mm</td>
                                        </tr>
                                        <tr>
                                            <td className="spec">Magazyn narzędzi</td>
                                            <td>Obrotowy magazyn 16 pozycji</td>
                                        </tr>
                                        <tr>
                                            <td className="spec">Program sterujący</td>
                                            <td>TPA CAD CAM</td>
                                        </tr>
                                        <tr>
                                            <td className="spec">Stół próżniowy</td>
                                            <td>8 - sekcyjny</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <img src="assets/infotec-evo-go-dimensions.webp" alt="infotec evo go wymiary" />
                        </div>
                        <img src="assets/evo-go-technical-data.webp" alt="specyfikacja techniczne infotec evo go" />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="upgrades">
                    <h3>EVO-GO upgrade</h3>
                    <div className="upgrade-list">
                        <KeyboardArrowLeftIcon className="arr-icon left" />
                        {upgrades.map((upgrade) => (
                            <div key={upgrade.id} className="upgrade-item">
                                <img src={upgrade.img} alt={upgrade.alt} />
                                <h5 className="upgrade-title">{upgrade.title}</h5>
                            </div>
                        ))}
                        <KeyboardArrowRightIcon className="arr-icon right" />
                    </div>

                </div>
            </div>
        </div >
    )
}
