import "./home.scss";
import { posts, machines } from "../../data";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function Home() {

  return (
    <div className="home">
      <div className="slider">
        <div className="wrapper">
          <h1>MASZYNY CNC Z PASJĄ</h1>
          <h2>INFOTEC CNC</h2>
          <div className="buttons">
            <button className="button-color">POZNAJ INFOTEC</button>
            <button className="button-black">POZNAJ INFOTEC</button>
          </div>
        </div>
      </div>

      <div className="row">
        <a href="/" id="infotec-cnc" className="column-2 banner" >
          <div className="banner-inner">
            <h3>#INFOTEC_CNC</h3>
            <h2>CENTRA NESTINGOWE</h2>
          </div>
        </a>
        <a href="/" id="infotec-engineering" className="column-2 banner">
          <div className="banner-inner">
            <h3>#INFOTEC_ENGINEERING</h3>
            <h2>LINIE ZROBOTYZOWANE</h2>
          </div>
        </a>
      </div>
      <div className="row">
        {posts.map((post) => (
          <div key={post.id} className="column-3 post-item">
            <a href={post.link}>
              <img src={post.img} alt={post.alt} />
              <p className="date">{post.date}</p>
              <h3>{post.title}</h3>
              <span className="divider" />
              <p>{post.short_description}</p>
            </a>
          </div>
        ))}
      </div>

      <div className="row infotec-engineering">
        <div className="infotec-engineering-inner-img">
          <img src="assets/Robot-infotec.webp" alt="robot infotec" />
        </div>
        <div className="infotec-engineering-inner">
          <img src="assets/infotec_engineering_logo.webp" alt="infotec engineering logo" />
          <h2>AUTOMATYZACJA I ROBOTYZACJA TWOJEJ FIRMY</h2>
          <p>Red Bull KTM Factory Racing’s Manuel Lettenbichler has won the 2022 FIM Hard Enduro World Championship. Claiming a dominant victory at the eighth and final round of the series - Hixpania Hard Enduro - the young German</p>
          <button className="button-outilned">CZYTAJ WIĘCEJ</button>
        </div>
      </div>

      <div className="row">
        <div id="infotec-banner" className="banner">
          <div>
            <h3>MASZYNY Z PASJĄ</h3>
            <h4>Z PASJĄ DO MASZYN</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="newsletter">
          <h3>Zapisz się do newslettera INFOTEC</h3>
          <button id="newsletter" className="button-outilned">ZAPISZ SIĘ</button>
        </div>
      </div>

      <div className="row">
        <div className="infotec-webinars">
          <div className="infotec-webinars-desc">
            <h2>NIE ZOSTAŃ W TYLE</h2>
            <h4>ROZWIJAJ SWIJĄ BRANŻE Z DUCHEM CZASU</h4>
            <p>Red Bull KTM Factory Racing’s Manuel Lettenbichler has won the 2022 FIM Hard Enduro World Championship. Claiming a dominant victory at the eighth and final round of the series - Hixpania Hard Enduro - the young German</p>
          </div>
          <div className="infotec-webinars-inner">
            <div>
              <button className="button-outilned">ZAPISZ SIĘ</button>
            </div>
            <div>
              <img src="assets/Go_foward.webp" alt="go forward arrow" />
            </div>
          </div>
        </div>
      </div>

      <div className="machine-items">
        {machines.map((machine) => (
          <div key={machine.id} className="column-3 machine-item">
            <a href={machine.link}>
              <img src={machine.img} alt={machine.alt} />
              <h3>{machine.title}</h3>
              <p>{machine.short_description}</p>
            </a>
          </div>
        ))}
      </div>

      <div className="row infotec-socials">
        <div className="infotec-social-inner-img">
          <img src="assets/infotec-social.webp" alt="infotec social media" />
        </div>
        <div className="infotec-social-inner">
          <img src="assets/logo.svg" alt="infotec logo" />
          <p>ODWIEDŹ NAS:</p>
          <div className="social-section">
            <a href="/"><FacebookIcon fontSize="large" /></a>
            <a href="/"><InstagramIcon fontSize="large" /></a>
            <a href="/"><LinkedInIcon fontSize="large" /></a>
            <a href="/"><YouTubeIcon fontSize="large" /></a>
          </div>
        </div>
      </div>
    </div >
  );
}
