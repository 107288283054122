import "./footer.scss";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer-item">
          <h3>ABOUT INFOTEC</h3>
          <ul>
            <li>O nas</li>
            <li>Polityka prywatności</li>
            <li>Historia</li>
            <li>Referencje</li>
            <li>Kontakt</li>
          </ul>
        </div>
        <div className="footer-item">
          <h3>MASZYNY</h3>
          <ul>
            <li>Centra obróbcze</li>
            <li>Frezarki CNC</li>
            <li>Plotery frezujące</li>
            <li>Maszyny specjalne</li>
          </ul>
        </div>
        <div className="footer-item">
          <h3>SERWIS</h3>
          <ul>
            <li>Kontakt</li>
            <li>Mapa dojazdów</li>
            <li>Zgłoś awarie</li>
          </ul>
        </div>
        <div className="footer-item">
          <h3>EVO LITE</h3>
          <ul>
            <li>Dokumenty do pobrania</li>
            <li>Katalogi do pobrania</li>
            <li>Ogólne warunki sprzedaży</li>
          </ul>
          <img src="assets/logo.svg" alt="infotec logo" />
        </div>
      </div>
      <div className="absolute-footer">
        Copyright © {new Date().getFullYear()} <strong>INFOTEC CNC</strong>
      </div>
    </footer>
  );
}
